<template lang="pug">
  .new-profile
    .app-loading(v-if="loading")
      v-progress-circular.progress(size="80" indeterminate color="#1438F5")
    NioStepper(
      v-else
      :ordered-steps="steps"
      :current-step="currentStep"
      :completed-steps="completedSteps"
      final-step-label="Save and Finish"
      @nextStep="nextStep"
      @previousStep="previousStep"
      @submit="saveProfile"
      @stepSelected="stepSelected($event)"
    )
      NioStep(
        v-if="steps.includes('Profile Info')"
        :valid="profileInfoValid"
        step-name="Profile Info"
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          ProfileInfoStep(
            :current-step="currentStep"
            :profile-details="stepPayloads['Profile Info']"
            @stepPayloadChanged="updatePayload('Profile Info', $event)"
            @setStepIncomplete="setStepIncomplete('Profile Info')"
          )
      //- :valid="stepPayloads['Configure Bucket'] === true"
      NioStep(
        v-if="steps.includes('Configure Bucket')"
        :valid="stepPayloads['Configure Bucket'] === true"
        step-name="Configure Bucket"
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          BucketConfigurationStep(
            v-if="bucketName"
            :curren-sStep="currentStep"
            :bucket-policy="bucketPolicy"
            :bucket-tag="bucketTag"
            :bucket-name="bucketName"
            @stepPayloadChanged="updatePayload('Configure Bucket', $event)"
            @setStepIncomplete="setStepIncomplete('Configure Bucket')"
          )
      NioStep(
        v-if="steps.includes('File Format')"
        :valid="stepPayloads['File Format'] !== null"
        step-name="File Format"
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          FileFormatStep(
            :current-step="currentStep"
            @stepPayloadChanged="updatePayload('File Format', $event)"
            @setStepIncomplete="setStepIncomplete('File Format')"
          )
      
</template>

<script>

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { baseUrl, setHeaders, getHeaders } from '@/utils/serviceLayer'
import axios from 'axios'
import ProfileInfoStep from './steps/ProfileInfo'
import BucketConfigurationStep from './steps/BucketConfiguration'
import FileFormatStep from './steps/FileFormat'

export default {
  components: { ProfileInfoStep, BucketConfigurationStep, FileFormatStep },
  data: () => ({
    loading: false,
    steps: ['Profile Info', 'Configure Bucket', 'File Format'],
    completedSteps: [],
    currentStep: 'Profile Info',
    stepPayloads: {
      'Profile Info': null,
      'Configure Bucket': null,
      'File Format': null
    },
    bucketName: null,
    bucketPolicy: null,
    bucketTag: null,
    prevBucketName: null,
    errorDialog: false
  }),
  computed: {
    profileInfoValid() {
      const profileInfo = this.stepPayloads['Profile Info'] 
      if (!profileInfo) {
        return false
      } else {
        return Boolean(profileInfo.name && profileInfo.description && profileInfo.bucketName)
      }
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    openApiInit(token) {
      
    },
    getBucketPolicy() {
      return new Promise((resolve, reject) => {
        axios.get(`${baseUrl}/bucket/${this.bucketName}/bucket-policy`, getHeaders()).then(resp => {
          this.loading = false
          this.bucketPolicy = resp.data
        }, err => {
          this.errorDialog = true
          this.loading = false
        })
      })
    },
    generateBucketTag() { 
      return new Promise((resolve, reject) => {
        axios.post(`${baseUrl}/bucket/${this.bucketName}/tag/refresh`, null, getHeaders()).then(resp => {
          this.bucketTag = resp.data
          this.loading = false
        }, err => {
          this.errorDialog = true
          this.loading = false
        })
      })
    },
    saveProfile() {
      this.loading = true
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      let reqBody = {
        name: this.stepPayloads['Profile Info'].name,
        description: this.stepPayloads['Profile Info'].description,
        bucket_name: this.bucketName,
        config: this.makeConfig()
      }
      axios.post(`${baseUrl}/profiles`, reqBody, getHeaders()).then(resp => {
        axios.post(`${baseUrl}/profiles/${resp.data.id}/enable`, null, getHeaders()).then(r => {
          this.profileDetails = {
            name: null,
            description: null
          }
          this.bucketName = null
          this.bucketPolicy = null
          this.bucketTag = null
          this.loading = false
          this.completedSteps = []
          this.currentStep = 'Profile Info'
          this.$emit('profileSaved')
        }, err => {
          this.errorDialog = true
          this.loading = false
        })
      }, err => {
        this.errorDialog = true
        this.loading = false
      })
    },
    setStepsIncomplete() {

    },
    makeConfig() {
      const fileConfig = this.stepPayloads['File Format']
      switch (fileConfig.fileType) {
        case 'csv':
        case 'tsv':
          return {
            "file_type": "csv",
            "header" : fileConfig.header,
            "delimiter": fileConfig.delimiter,
            "quote": fileConfig.quote,
            "escape": fileConfig.escapeCharacter
          }
        case 'json':
          return {
            "file_type":"json"
          }
        case 'parquet':
          return {
            "file_type":"parquet"
          }
        default:
          return undefined
      }
    },
    nextStep() {
      if (this.currentStep === 'Profile Info') {
        if (this.stepPayloads['Profile Info']?.bucketName !== this.prevBucketName) {
          this.prevBucketName = this.stepPayloads['Profile Info'].bucketName
          this.bucketPolicy = null
          this.bucketTag = null
          this.getBucketPolicy()
          this.generateBucketTag()
        }
      }
      if (!this.completedSteps.includes(this.currentStep)) {
        this.completedSteps.push(this.currentStep)
      }

      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) + 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    previousStep() {
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) - 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    stepSelected(stepName) {
      this.currentStep = stepName
    },
    updatePayload(step, payload) {
      this.stepPayloads[step] = payload
      if (step === 'Profile Info') {
        this.bucketName = payload.bucketName
      }
    },
    scrollToStep(stepIndex) { 
      this.$nextTick(() => {
        const top = 35 + stepIndex * 130
        parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: top
          }
        },"*")
      })       
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.new-profile
  ::v-deep .nio-step-header-slat .nio-slat-action
    justify-content: flex-end
        
</style>