import { render, staticRenderFns } from "./ConfirmDeleteDialog.vue?vue&type=template&id=bc234d42&scoped=true&lang=pug"
import script from "./ConfirmDeleteDialog.vue?vue&type=script&lang=js"
export * from "./ConfirmDeleteDialog.vue?vue&type=script&lang=js"
import style0 from "./ConfirmDeleteDialog.vue?vue&type=style&index=0&id=bc234d42&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc234d42",
  null
  
)

export default component.exports