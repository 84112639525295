const specialCharacters = [
  { 
    name: 'Pipe - "|"',
    value: '|'
  }, {
    name: 'Comma - ","',
    value: ','
  }, {
    name: 'Semi-colon - ";"',
    value: ';'
  }, {
    name: 'Tab - "\\t"',
    value: '\t'
  }, {
    name: 'Space - " "',
    value: ' '
  },
  {
    name: 'Comma - ,',
    value: ','
  },
  {
    name: 'Double Quote - ""',
    value: '"'
  }
]

const fileTypes = [
  {
    name: 'CSV',
    value: 'csv'
  },
  {
    name: 'TSV',
    value: 'tsv'
  },
  {
    name: 'JSON',
    value: 'json'
  },
  {
    name: 'Parquet',
    value: 'parquet'
  }
]

export {
  specialCharacters,
  fileTypes
}