const baseUrl =  window.location.host === 's3-connector.narrative.io' ? 'https://aws-s3.narrativeconnectors.narrative.io' : 'https://aws-s3-dev.narrativeconnectors.com'

let headers = null

function setHeaders(token) {
	headers = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	}
} 

function getHeaders() {
	return headers
}

export { 
	baseUrl,
	setHeaders,
	getHeaders
}