<template lang="pug">
  .profile-info-step
    NioDestinationConnectorSettings(
      v-model="model"
    )
      template(v-slot:settings-controls)
        NioConnectorSettingsControl(
          title="Bucket Name"
          description="The location of the bucket you would like to send data to within your S3 account. "     
        )
          template(v-slot:control)
            NioTextField(
              v-model="model.bucketName"
              :rules="[rules.required]"
              label="Bucket Name"
              validate-on-blur
            )
</template>

<script>

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { baseUrl, setHeaders, getHeaders } from '@/utils/serviceLayer'
import axios from 'axios'

export default {
  props: {
    profileDetails: { type: Object, required: false }
  },
  data: () => ({
    loading: true,
    model: {
      name: null,
      description: null,
      bucketName: null
    },
    rules: {
      required: function(value) { return !!value || 'Required' }
    }  
  }),
  computed: {
    profileValid() {
      return this.model && this.model.name && this.model.description && this.model.bucketName
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        if (this.profileValid) {
          this.$emit('stepPayloadChanged', this.model)
        }
      }
    },
    profileDetails() {
      this.model = this.profileDetails
    }
  },
  mounted() {
    if (this.profileDetails) {
      this.model = this.profileDetails
    }
  }
};

</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/"

</style>