<template lang="pug">
  .configure-bucket(v-if="bucketPolicy && bucketTag")
    .policy-and-tags
      .bucket-policy.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Bucket Policy
          .description.nio-p.text-primary-dark <p>Narrative requires you to add a bucket policy to your AWS bucket. This ensures that Narrative can successfully connect to and deliver data to the bucket.</p><p>To add the bucket policy, navigtate to your bucket in the AWS console. Click on <strong>Properties</strong> and under the <strong>Permissions</strong> section click <strong>Edit</strong>. Copy and paste the policy provided into the form and click <strong>Save.</strong></p><p>If you prefer the use the AWS CLI, you can read about adding a bucket policy <a href="https://awscli.amazonaws.com/v2/documentation/api/latest/reference/s3api/put-bucket-policy.html" target="_blank">here</a>.</p>
        .filter-value
          .policy-text.text-primary-dark.nio-p-small {{ JSON.stringify(bucketPolicy, null, 2) }}
          NioButton.copy-button(
            :class="{copied: textCopied.bucketPolicy}"
            normal-tertiary
            @click="copyToClipboard('bucketPolicy')"
          ) {{ textCopied.bucketPolicy ? 'Copied to clipboard!' : 'Copy' }} 
      .bucket-tag.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Bucket Tag
          .description.nio-p.text-primary-dark <p>Narrative also requires you add tag to your AWS bucket. This ensures that you are the owner of the bucket.</p><p>To add the tag, navigtate to your bucket in the AWS console. Click on <strong>Properties</strong> and under the <strong>Tags</strong> section, click <strong>Edit</strong>. Copy and paste the tag key and value provided into the form and click <strong>Save.</strong></p><p>If you prefer the use the AWS CLI, you can read about adding a bucket tag <a href="https://awscli.amazonaws.com/v2/documentation/api/latest/reference/s3api/put-bucket-tagging.html" target="_blank">here</a>.</p>
        .filter-value
          .entry
            .nio-p.text-primary-darker.nio-bold Key:
            .nio-p.text-primary-dark {{ bucketTag.name }}
            NioButton.copy-button(
              :class="{copied: textCopied.tagKey}"
              normal-tertiary
              @click="copyToClipboard('tagKey')"
            ) {{ textCopied.tagKey ? 'Copied to clipboard!' : 'Copy' }} 
          .entry
            .nio-p.text-primary-darker.nio-bold Value:
            .nio-p.text-primary-dark {{ bucketTag.value }}
            NioButton.copy-button(
              :class="{copied: textCopied.tagValue}"
              normal-tertiary
              @click="copyToClipboard('tagValue')"
            ) {{ textCopied.tagValue ? 'Copied to clipboard!' : 'Copy' }} 
      .test-connection.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Test Connection
          .description.nio-p.text-primary-dark Once you've configured your bucket, it's time to make sure that Narrative can connect to the bucket.
        .filter-value     
          v-progress-circular.progress(v-if="testingConnection" size="24" indeterminate color="#1438F5")
          NioButton.test-connection-button(
            @click="testConnection"
            normal-secondary
            :disabled="testingConnection"
          ) Test Connection    
          NioAlert.test-connection-alert(
            v-if="!testingConnection && (connectionSuccess || connectionError)"
            :visible="connectionSuccess || connectionError"
            :dismissable="false"
            :class="{'alert-success': connectionSuccess, 'alert-error': connectionError}"
          )
            .alert-content
              .alert-icon
                NioIcon(
                  :size="16"
                  :color="connectionSuccess ? '#67AC5B' : '#FF2054'"
                  name="utility-times"
                )
              .message(:style="{color: connectionSuccess ? '#67AC5B' : '#FF2054'}") {{ connectionError ? connectionErrorMessage : 'Success! Profile connected.'}}
    NioDialog(v-model="regionConstraintDialog")
      RegionConstraintDialog(
        :bucket-region="bucketRegion"
        :supported-regions="supportedBucketRegions"
        @back="regionConstraintDialog = false"
      )
  .step-loading(v-else)
    v-progress-circular.progress(
      size="80" 
      color="#1438F5"
      indeterminate 
    )     

</template>

<script>

import { baseUrl, getHeaders } from '@/utils/serviceLayer'
import axios from 'axios'
import RegionConstraintDialog from '../../RegionConstraintDialog'

export default {
  components: { RegionConstraintDialog },
  props: {
    bucketPolicy: { type: Object, required: false }, 
    bucketTag: { type: Object, required: false }, 
    bucketName: { type: String, required: true }
  },
  data: () => ({
    testingConnection: false,
    connectionSuccess: false,
    connectionError: false,
    connectionErrorMessage: null,
    textCopied: {
      bucketPolicy: false,
      tagKey: false,
      tagValue: false
    },
    regionConstraintDialog: false,
    supportedBucketRegions: ["us-east-1"],
    bucketRegion: null
  }),
  methods: {
    testConnection() {
      this.testingConnection = true
      const defaultErrorMessage = 'Connection failed. Your bucket permissions may not be properly configured.'
      axios.post(`${baseUrl}/bucket/${this.bucketName}/test`, null, getHeaders()).then(r => {
        if (r.data && !this.supportedBucketRegions.includes(r.data.region)) {
          this.connectionErrorMessage = 'Bucket region is not supported.'
          this.bucketRegion = r.data.region
          this.regionConstraintDialog = true
          this.connectionSuccess = false
          this.connectionError = true
          this.testingConnection = false
          this.$emit('stepPayloadChanged', false)
        } else {
          this.connectionSuccess = true
          this.connectionError = false
          this.testingConnection = false
          this.$emit('stepPayloadChanged', true)
        }
      }, err => {
        this.connectionErrorMessage = defaultErrorMessage
        this.connectionSuccess = false
        this.connectionError = true
        this.testingConnection = false
        this.$emit('stepPayloadChanged', false)
      })
    },
    copyToClipboard(element) {
      const text = element === 'bucketPolicy' ? JSON.stringify(this.bucketPolicy) : element === 'tagKey' ? this.bucketTag.name : this.bucketTag.value
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy)
      dummy.value = text
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
      this.textCopied[element] = true
      setTimeout(() => { this.textCopied[element] = false }, 2000)
    }
  }
};
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-group"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.configure-bucket
  .policy-and-tags
    +nio-filter-group
    .copy-button
      position: absolute
      top: 0rem
      right: 0rem
      height: 1.1875rem !important
      min-width: unset !important
      opacity: 0.6
      ::v-deep .v-btn__content
        color: $c-primary-dark
        font-weight: normal
      &.copied ::v-deep .v-btn__content
        color: $c-success
    .filter
      +nio-filter-header
      .title-description
        padding-right: 2rem
    & > .filter + .filter
      border-top: 0.0625rem solid $c-primary-lighter
    .bucket-policy
      .filter-value
        position: relative
        .policy-text
          padding: 1rem
          background-color: $c-canvas
          white-space: break-spaces
          width: 100%
          border: 0.0625rem solid $c-primary-lighter
          border-radius: 0.75rem
        .copy-button
          right: 1rem
    .bucket-tag
      .entry
        width: 100%
        position: relative
  .test-connection
    .filter-value
      position: relative
      .v-progress-circular
        position: absolute
        left: 5.625rem
        top: 0.4375rem
        margin-left: -0.75rem
        z-index: 2
      .test-connection-button
        margin-top: 0rem
        width: 11.75rem
        ::v-deep .v-btn__content
          justify-content: flex-start !important
      .test-connection-alert
        padding: 0rem
        margin: 0rem
        margin-top: 1rem
        border: none
        .alert-content
          display: flex
          justify-content: center
          align-items: flex-start
          .alert-icon
            flex-shrink: 0
            margin-right: 0.375rem
            border: 0.0625rem solid
            height: 1.5rem
            width: 1.5rem
            border-radius: 0.75rem
            .nio-icon
              line-height: 1.5rem
              ::v-deep svg
                margin-bottom: 0.0313rem
                margin-left: 0.0625rem
          .message
            text-align: left
        &.alert-success
          .alert-icon
            border-color: $c-success
        &.alert-error
          .alert-icon
            border-color: $c-error
</style>