import { render, staticRenderFns } from "./RegionConstraintDialog.vue?vue&type=template&id=4b33000c&scoped=true&lang=pug"
import script from "./RegionConstraintDialog.vue?vue&type=script&lang=js"
export * from "./RegionConstraintDialog.vue?vue&type=script&lang=js"
import style0 from "./RegionConstraintDialog.vue?vue&type=style&index=0&id=4b33000c&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b33000c",
  null
  
)

export default component.exports