<template lang="pug">
  .profile-list
    NioSlatTable(
      v-if="columns"
      :items="profiles"
      :columns="columns"
      :header-modules="['count']"
      action="expand"
    )
      template(v-slot:item-expanded="slotProps")
        .split-row
          .display-row.display-table
            .display-column
              .nio-h4.text-primary-darker Description
              .nio-p.text-primary-dark(v-if="slotProps.item.description") {{ slotProps.item.description }}
              .nio-p.text-primary-dark.empty(v-else) None provided      
          .display-row.display-table
            .display-column
              .nio-h4.text-primary-darker Profile ID
              .nio-p.text-primary-dark.description {{ slotProps.item.id }}
        .split-row
          .display-row.display-table
            .display-column
              .nio-h4.text-primary-darker Bucket Name
              .nio-p.text-primary-dark(v-if="slotProps.item.bucket_name") {{ slotProps.item.bucket_name }}
          .display-row.display-table
            .display-column
              .nio-h4.text-primary-darker File Type
              .nio-p.text-primary-dark.description {{ getFileTypeLabel(slotProps.item.config.file_type) }}
        .split-row
          .display-row.display-table(v-if="slotProps.item.config.delimiter")
            .display-column
              .nio-h4.text-primary-darker Delimiter
              .nio-p.text-primary-dark {{ getSpecialCharLabel(slotProps.item.config.delimiter) }}
          .display-row.display-table(v-if="slotProps.item.config.escape")
            .display-column
              .nio-h4.text-primary-darker Escape Character
              .nio-p.text-primary-dark {{ getSpecialCharLabel(slotProps.item.config.escape) }}
        .split-row
          .display-row.display-table(v-if="slotProps.item.config.quote")
            .display-column
              .nio-h4.text-primary-darker Quote Character
              .nio-p.text-primary-dark {{ getSpecialCharLabel(slotProps.item.config.escape) }}
          .display-row.display-table(v-if="slotProps.item.config.header === true || slotProps.item.config.header === false") 
            .display-column
              .nio-h4.text-primary-darker Include Header?
              .nio-p.text-primary-dark.description {{ slotProps.item.config.header === true ? 'Yes' : 'No'  }}
        .subscription-footer
          .subscription-actions(v-if="slotProps.item.status !== 'archived'")
            NioButton(
              caution-text 
              @click="deleteProfile(slotProps.item)"
            ) Delete Profile
</template>

<script>

import moment from 'moment'
import { specialCharacters, fileTypes } from '../fileConfigOptions'

export default {
  props: {
    "profiles": { type: Array, required: true }
  },
  data: () => ({
    columns: null
  }),
  mounted() {
    this.makeColumns()
  },
  methods: {
    makeColumns() {
      this.columns = [
        {
          name: "slat",
          props: {
            title: 'name',
            subtitle: "description"
          }
        },
        {
          name: "status",
          label: "Status",
          computed: this.computeStatus
        }
      ]
    },
    computeLastConnected(item) {
      return moment(item.last_connected).format('MMM D, YYYY')
    },
    computeStatus(item) {
      return this.capitalize(item.status)
    },
    viewProfileSubscriptions(profile) {

    },
    deleteProfile(profile) {
      this.$emit('deleteProfile', profile) 
    },
    capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    getSpecialCharLabel(value) {
      return specialCharacters.find(char => char.value === value).name
    },
    getFileTypeLabel(value) {
      return fileTypes.find(type => type.value === value).name
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/table/_slat-table-expanded-row"

.profile-list
  ::v-deep .expanded-row
    +nio-slat-table-expanded-row
  ::v-deep .v-data-table
    tr.expanded 
      background-color: $c-canvas
  
</style>