<template lang="pug">
  .file-format-step
    .filter
      .title-description
        .filter-title.nio-h4.text-primary-darker File Type
        .description.nio-p.text-primary-dark Set your dataset file type to CSV, JSON, TSV,  or Parquet.
      .filter-value
        NioSelect(
          v-model="model.fileType"   
          :items="options.fileType"
          label="File Type"
          item-text="name"
          item-value="value"
        )
    .filter(v-if="showFileOptions")
      .title-description
        .filter-title.nio-h4.text-primary-darker Delimiter
        .description.nio-p.text-primary-dark The character separating the data in your file into distinct fields.
      .filter-value
          NioSelect(
            v-model="model.delimiter"   
            :items="options.specialChars"
            label="Delimiter"
            item-text="name"
            item-value="value"
          )
    .filter(v-if="showFileOptions")
      .title-description
        .filter-title.nio-h4.text-primary-darker Escpape Character
        .description.nio-p.text-primary-dark CSV uses a double quote as default but you can change this to another character such as semi-colon, tab, pipe, or space.
      .filter-value
          NioSelect(
            v-model="model.escapeCharacter"   
            :items="options.specialChars"
            label="Escape Character"
            item-text="name"
            item-value="value"
          )
    .filter(v-if="showFileOptions")
      .title-description
        .filter-title.nio-h4.text-primary-darker Header
        .description.nio-p.text-primary-dark If the first row of your dataset contains headers, turn this ON.
      .filter-value
        NioSwitch(
          v-model="model.header"
          label="Turn on headers"
          item-text="name"
          item-value="value"
        )
    .filter(v-if="showFileOptions")
      .title-description
        .filter-title.nio-h4.text-primary-darker Quote
        .description.nio-p.text-primary-dark The default quote for a CSV file is a double quote.
      .filter-value
        NioSelect(
          v-model="model.quote"   
          :items="options.specialChars"
          label="Quote"
          item-text="name"
          item-value="value"
        )
</template>

<script>

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { specialCharacters, fileTypes } from '../../fileConfigOptions'
import { baseUrl, setHeaders, getHeaders } from '@/utils/serviceLayer'
import axios from 'axios'

export default {
  data: () => ({
    loading: true,
    model: {
      fileType: 'csv',
      delimiter: ',',
      escapeCharacter: '"',
      header: true,
      quote: '"'
    },
    prevFileType: 'csv',
    options: {
      fileType: fileTypes,
      specialChars: specialCharacters
    }
  }),
  computed: {
    showFileOptions() {
      return this.model.fileType === 'csv' || this.model.fileType === 'tsv'
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        if (this.prevFiletype !== this.model.fileType) {
          if (this.model.fileType === 'csv') {
            this.model.delimiter = ','
          } else if (this.model.fileType === 'tsv') {
            this.model.delimiter = '\t'
          }
        } 
        this.prevFiletype = this.model.fileType
        this.$emit('stepPayloadChanged', this.model)
      }
    }
  },
  mounted() {
    this.$emit('stepPayloadChanged', this.model)
  }
};

</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-group"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.file-format-step
  +nio-filter-group
  .filter
    +nio-filter-header
    .nio-select
      width: 100%
      height: 3.375rem 
  & > .filter + .filter
    border-top: 0.0625rem solid $c-primary-lighter
</style>