<template lang="pug">
  .region-contraint-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-warning"
    )
    h3.nio-h3.text-primary-darker Unsupported Bucket Region
    p.nio-p.text-primary-dark It looks like your bucket is in the AWS region {{ bucketRegion }}. Unfortunately, only buckets from {{ validRegions }} are supported at this time.
    .actions
      NioButton(
        normal-secondary
        @click="back"
      ) Back
</template>

<script>

export default {
  props: {
    bucketRegion: { type: String, required: true },
    supportedRegions: { type: Array, required: true }
  },
  computed: {
    validRegions() {
      return this.supportedRegions.reduce((acc, region, index) => {
        if (index === this.supportedRegions.length - 1 && this.supportedRegions.length > 1) {
          return acc + 'or ' + region
        }
        return acc + region + `${this.supportedRegions.length > 1 ? ', ' : ''}` 
      } , '')
    }
  },
  methods: {
    back() {
      this.$emit('back')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.region-contraint-dialog
  padding: 6.25rem 1.5rem 4.875rem 1.5rem
  background-color: $c-white
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 1rem
  h3
    margin-bottom: 0.5rem
    text-align: center
  p    
    margin-bottom: 2.5rem
    text-align: center
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 1rem  
</style>